<template>
  <div class="content-wrapper">
    <header-title
      title="Nps"
      iconTitle="nav-icon fa fa-star font-color-custom"
    />
    <div v-if="loading">
      <h1>Loading...</h1>
    </div>
    <div v-else>
      <div class="container container-box">
        <average-votes :votes="votesNotIgnored" />
        <total-impressions
          :chartdata="chartdata"
          :options="options"
          :numberOfViews="numberOfViews"
        />
        <percentage-wall :votes="votes" />
        <line-votes :votes="votesNotIgnored" />
      </div>
      <div class="d-flex justify-content-center align-itens-center companies mt-3">
        <list-average-votes-companies @select_company_vote="(number) => companySelected = number" :votes="limitVotes" />
        <list-average-votes-customers :votes="filterCustomers" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    "header-title": () =>
      import("../../cs/notifications/components/HeaderTitle.vue"),
    "total-impressions": () => import("./Impressoes.vue"),
    "average-votes": () => import("./averageVotes.vue"),
    "percentage-wall": () => import("./PercentageWall.vue"),
    "line-votes": () => import("./LineVotes.vue"),
    "list-average-votes-companies": () => import("./ListCompaniesAverageVotes.vue"),
    "list-average-votes-customers": () => import("./ListCustomerAverageVotes.vue")
  },
  data() {
    return {
      loading: true,
      numberOfViews: 0,
      votesNotIgnored: [],
      companiesFilteredOut: [],
      companySelected: '',
      customersOfCompanySelected: [],
      votes: [],
      voted: 0,
      ignored: 0,
      chartdata: {
        labels: ["Views", "Ignored"],
        datasets: [
          {
            label: "Data One",
            backgroundColor: ["#24aae1", "#6dcee8"],
            data: [0,0],
            pointBackgroundColor: "#000000",
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: "bottom",
          },
          title: {
            display: true,
            text: "Views",
          },
        },
      },
    };
  },
  computed: {
    limitVotes(){
      return this.companiesFilteredOut.sort((a, b) => {
        if (a.average < b.average) return 1 
        if (a.average > b.average) return -1
        return 0 
      })
    },
    filterCustomers(){
      return this.votes
      .filter(vote => vote.companyId === this.companySelected && vote.vote >= 0 && !vote.ignored)
      .sort((a, b) => {
        if (a.vote < b.vote) return 1 
        if (a.vote > b.vote) return -1
        return 0 
      })
    }
  },
  mounted() {
    this.$tallos_admin_api.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.$store.getters.tokenJWT}`;
    this.getVotes()
  },
  methods: {
    getVotes: async function() {
      const companiesIds = []
      let votesIgnored = 0
      const {data} = await this.$tallos_admin_api
        .get(`nps/${this.$route.params.id}`)
      this.votes = data.votes
      this.numberOfViews = data.votes.length
      data.votes.map(vote => {
        if(!vote.ignored) {
          this.$set(this.votesNotIgnored, this.votesNotIgnored.length, vote)
          companiesIds.push(vote.companyId)
        } else {
          votesIgnored++
        }
      })
      this.chartdata.datasets[0].data[0] = this.votesNotIgnored.length
      this.chartdata.datasets[0].data[1] = votesIgnored
      this.countCompanies(companiesIds)
      this.calcAverageVotesOfCompany()
      this.getNameOfCompany()
      this.loading = false
    },
    countCompanies(companies) {
      const countMap = Object.create(null);
      for (const company of companies) {
        if (!countMap[company]) {
          countMap[company] = {
            company: company,
            count: 1,
          };
        this.companiesFilteredOut.push({
            company: company,
            count: 1,
            average: 0
          });
        } else {
          countMap[company].count += 1;
          this.companiesFilteredOut.map(companyFind => {
            if(companyFind.company === company) {
              companyFind.count += 1
            }
          })
        }
      }
    },
    calcAverageVotesOfCompany: function() {
      this.companiesFilteredOut.map(company => {
        let arrVotes = []
        this.votes.map(vote => {
          if(vote.companyId === company.company && vote.vote >= 0 && !vote.ignored) {
            arrVotes.push(vote.vote)
          }
        })
        const sumVotes = arrVotes.reduce((a, b) => a + b) 
        company.average = Math.floor(sumVotes / arrVotes.length)
      })
    },
    getNameOfCompany: function() {
      this.companiesFilteredOut.map(company => {
        const found = this.votes.find(vote => vote.companyId === company.company)
        company.companyName = found.companyName
      })
    }
  }
};
</script>

<style scoped>

.container-box {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.companies {
  gap: 20px
}

@media screen and (max-width: 1220px) {
  .companies {
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
}
</style>